import React, { useEffect, useMemo, useState } from "react"
import { css } from "@emotion/react"

import { CustomerSite, useAuth, useSites, useIsUser, UserId, useUserProfile } from "@ncs/ncs-api"
import {
	AddressFormModalEcomm,
	AnimatedEntrance,
	Box,
	ButtonEcomm,
	GenericAddress,
	getAddressFields,
	getGenericAddress,
	Paragraph,
	ParagraphList,
	PhoneInputEcomm,
	SearchableSiteSelectorEcomm,
	ThrottledTextarea,
	UspsAddress,
} from "@ncs/web-legos"

import { useShopContext } from "~/contexts"

interface ChooseShippingAddressEcommProps {
	updateComment: (newValue: string | null) => void
}

export const ChooseShippingAddressEcomm: React.FC<ChooseShippingAddressEcommProps> = ({
	updateComment,
}) => {
	const { user } = useAuth()
	const [profile] = useUserProfile(user?.id)
	const [sites, sitesLoading] = useSites()
	const auth = useAuth()
	const isDb = useIsUser(UserId.DrivenBrands)
	const isPunchout = !!auth.punchOutSessionId
	const [{ checkout }, shopDispatch] = useShopContext()
	const [showAlternateAddressForm, setShowAlternateAddressForm] = useState(false)
	const [selectedSite, setSelectedSite] = useState<CustomerSite | null>(null)
	const [alternateAddress, setAlternateAddress] = useState<UspsAddress | null>(null)
	const [phoneNumber, setPhoneNumber] = useState<string | null>(null)

	const hasSites = useMemo(() => {
		return !!sites && sites.length > 0
	}, [sites])

	useEffect(() => {
		// If the sites list is only one site long, go ahead and select it automatically.
		if (!sitesLoading && sites?.length === 1 && !checkout.shipToSiteId) {
			const site = sites[0]

			if (!site?.phone) {
				// eslint-disable-next-line no-console
				console.log(
					"this site does not have a phone number, please include one for your order"
				)
			}

			shopDispatch({
				type: "select shipping site",
				payload: {
					siteId: site.id,
				},
			})
		}
	}, [checkout.shipToSiteId, sitesLoading, shopDispatch, sites])

	useEffect(() => {
		// may need to update how this sets the address.
		if (alternateAddress) {
			shopDispatch({
				type: "select alternate address",
				payload: {
					alternateAddress: getGenericAddress(alternateAddress),
					preserveShipToId: true,
				},
			})
		}

		if (phoneNumber) {
			shopDispatch({
				type: "set checkout phone",
				payload: phoneNumber,
			})
		}
	}, [alternateAddress, phoneNumber, shopDispatch])

	const selectedAddress: GenericAddress | null = useMemo(() => {
		if (checkout.alternateAddress) {
			return checkout.alternateAddress
		} else if (checkout.shipToSiteId) {
			const site = (sites ?? []).find((s) => s.id === checkout.shipToSiteId)

			if (!site && !sitesLoading) {
				// eslint-disable-next-line no-console
				console.warn(
					"The site ID in checkout state does not match any of the sites coming back this customer."
				)
			}

			if (!site?.phone) {
				// eslint-disable-next-line no-console
				console.log(
					"this site does not have a phone number, please include one for your order"
				)
			}

			if (site) {
				return getGenericAddress(site)
			}
		}
		return null
	}, [checkout.shipToSiteId, checkout.alternateAddress, sites, sitesLoading])

	const onSiteSelect = (site: CustomerSite | null) => {
		setAlternateAddress(null)
		setSelectedSite(site)
		if (site) {
			shopDispatch({
				type: "select shipping site",
				payload: {
					siteId: site.id,
				},
			})
		}
	}

	const onAlternateAddressSave = (address: UspsAddress, phone: string) => {
		setShowAlternateAddressForm(false)
		setSelectedSite(null)
		setAlternateAddress(address)
		setPhoneNumber(phone)
	}

	return (
		<>
			<Paragraph css={addressHelpText} mb={1}>
				{profile?.isGuest ?
					"Add an address below to ship your order."
				:	"Ship to your NCS-registered site or add an alternate address below."}
			</Paragraph>
			{!profile?.isGuest && (
				<SearchableSiteSelectorEcomm
					initialId={checkout.shipToSiteId}
					value={selectedSite}
					onChange={onSiteSelect}
				/>
			)}

			{selectedAddress ?
				<Box d="flex" css={addressContainer} justifyContent="space-between">
					<Box>
						<Paragraph css={addressText}>Address</Paragraph>
						<div>
							<ParagraphList
								lines={getAddressFields(selectedAddress, undefined, phoneNumber)}
							/>
						</div>
					</Box>
					<Box>
						<ButtonEcomm
							css={css`
								margin-top: 0;
							`}
							variant="text"
							onClick={() => setShowAlternateAddressForm(true)}
						>
							<span
								css={css`
									color: var(--Brand-Cobalt, #0b75e1);
									text-align: center;
									font-family: "Atlas Grotesk";
									font-size: 14px;
									font-style: normal;
									font-weight: 700;
									line-height: 28px; /* 200% */
									text-decoration-line: underline;
									text-decoration-style: solid;
									text-decoration-skip-ink: none;
									text-decoration-thickness: auto;
									text-underline-offset: auto;
									text-underline-position: from-font;
								`}
							>
								Change Address
							</span>
						</ButtonEcomm>
					</Box>
				</Box>
			:	!isPunchout && (
					<>
						{!alternateAddress && (
							<ButtonEcomm
								variant="secondary-cta"
								onClick={() => setShowAlternateAddressForm(true)}
								containerProps={{
									mt: 2,
								}}
							>
								Add Address
							</ButtonEcomm>
						)}
					</>
				)
			}

			{selectedAddress && (
				<AnimatedEntrance show={!!selectedAddress} d="flex" mt={2} gap={1}>
					<PhoneInputEcomm
						required={!isDb}
						value={checkout.phone}
						onChange={(value) => {
							shopDispatch({
								type: "set checkout phone",
								payload: value,
							})
						}}
						fillContainer={false}
						label={
							isDb ? "Delivery contact phone" : "Delivery contact phone (required)"
						}
						width={18}
					/>
				</AnimatedEntrance>
			)}

			{!isPunchout && (
				<Box mt={1}>
					<ThrottledTextarea
						css={css`
							textarea {
								height: 72px;
								font-family: "Atlas Grotesk";
								font-size: 14px;
								font-style: normal;
								font-weight: 500;
								line-height: 16px;
								color: #374151;
							}
						`}
						value={checkout.comment}
						onChange={updateComment}
						label="Additional comments"
						placeholder="Any additional comments about your order..."
					/>
				</Box>
			)}

			<AddressFormModalEcomm
				isOpen={showAlternateAddressForm}
				onClose={() => setShowAlternateAddressForm(false)}
				onSave={onAlternateAddressSave}
				hardCodedFields={
					user?.name ?
						{
							name: user.name,
						}
					:	undefined
				}
				allowCanada={hasSites}
			/>
		</>
	)
}

const addressHelpText = css`
	color: var(--Neutrals-700, #374151);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px; /* 131.25% */
	color: var(--Neutrals-700, #374151);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px; /* 131.25% */
`

const addressText = css`
	width: 288px;
	height: 24px;
	flex-shrink: 0;
	color: var(--Neutrals-900, #111827);
	font-family: "Atlas Grotesk";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 171.429% */
`

const addressContainer = css`
	width: 100%;
	padding: 16px;
	flex-shrink: 0;
	border-radius: 4px;
	background: var(--Neutrals-100, #f3f4f6);
`
