import React, { useEffect, useState } from "react"
import { captureException, captureMessage } from "@sentry/minimal"
import { css } from "@emotion/react"
import {
	makeApiErrorMessage,
	ShippingService,
	ShippitAccessorial,
	shippitApi,
	ShippitItem,
	ShippitResponse,
	useDefaultAccessorials,
	useIsUser,
	UserId,
} from "@ncs/ncs-api"
import { formatCurrency, formatDate } from "@ncs/ts-utils"
import {
	Box,
	CheckboxGroupEcomm,
	Divider,
	ErrorText,
	GridContainer,
	GridItem,
	LoadingSpinner,
	Paragraph,
	PartImage,
	PriceEcomm,
	RadioGroupEcomm,
	SkeletonRows,
	TrackingEvent,
	trackEvent,
} from "@ncs/web-legos"

import { useShopContext } from "~/contexts"
import { QuantityControl, RemoveFromCartModal } from "~/shared-components"

interface ShippingSelectorEcommType {
	cost: number | null
	estimatedDelivery: string
	label: string
	name: ShippingService
}

interface ChooseShippingMethodsEcommProps {
	setHasShippingMethod: (value: boolean) => void
}

export const ChooseShippingMethodsEcomm: React.FC<ChooseShippingMethodsEcommProps> = ({
	setHasShippingMethod,
}) => {
	const isDb = useIsUser(UserId.DrivenBrands)
	const [shippitResponse, setShippitResponse] = useState<ShippitResponse | null>(null)
	const [shippitError, setShippitError] = useState<string | null>(null)
	const [partBeingRemoved, setPartBeingRemoved] = useState<ShippitItem | null>(null)

	const [{ cart, checkout }, shopDispatch] = useShopContext()

	const [accessorialDefaults] = useDefaultAccessorials(checkout.shipToSiteId)

	// Get the full, raw shipment option objects from Shippit, with prices for all the choices.
	useEffect(() => {
		const callShippit = async (destination: {
			shipToSiteId?: string
			alternateAddress?: {
				address1?: string | null
				address2: string
				city: string
				state: string
				postalcode: string
			}
		}) => {
			try {
				shopDispatch({
					type: "set shippit loading status",
					payload: true,
				})

				const response = await shippitApi(cart, destination)

				if (Object.values(response.shipments).some((s) => s.service.length === 0)) {
					captureMessage("Shippit returned no options for one of the shipments!", {
						extra: {
							partIds: cart.map((item) => item.part.id),
						},
					})
					throw new Error(
						"Unfortunately there was an error getting shipping options for one of the parts in your order. Please contact support."
					)
				}

				// Set local state to what we got back. We'll show this in the UI for the
				// user to choose from.
				setShippitResponse(response)

				// Shop context needs to know how we're splitting the order up.
				shopDispatch({
					type: "set order shipments",
					payload: {
						shipments: Object.entries(response.shipments).map(
							([productType, option]) => {
								// Default to the cheapest (slowest) option.
								const defaultService = option.service.sort((a, b) =>
									(a.cost ?? 0) < (b.cost ?? 0) ? -1 : 1
								)[0]

								return {
									productType,
									parts: option.items,
									service: defaultService.name,
									cost: defaultService.cost,
								}
							}
						),
						hasChemical: Object.keys(response.shipments).some(
							(key) => key === "chemicals"
						),
					},
				})
			} catch (e) {
				setShippitError(makeApiErrorMessage(e))
				captureMessage("Shippit returned an error!", {
					extra: {
						cart,
						destination,
						e,
					},
				})
				captureException(e)
				setShippitResponse(null)
				shopDispatch({
					type: "set order shipments",
					payload: {
						shipments: null,
						hasChemical: false,
					},
				})
			}
		}

		if (checkout.shipToSiteId || checkout.alternateAddress) {
			setShippitError(null)
			void callShippit({
				shipToSiteId: checkout.shipToSiteId ?? undefined,
				alternateAddress:
					checkout.alternateAddress ?
						{
							address1: checkout.alternateAddress.address1,
							address2: checkout.alternateAddress.address2,
							city: checkout.alternateAddress.city,
							state: checkout.alternateAddress.state,
							postalcode: checkout.alternateAddress.zip,
						}
					:	undefined,
			})
		}
	}, [cart, shopDispatch, checkout.shipToSiteId, checkout.alternateAddress])

	const selectShippingMethod = (
		shipmentProductType: string,
		serviceName: ShippingService,
		serviceCost: number | null
	) => {
		shopDispatch({
			type: "select shipping service for shipment",
			payload: {
				shipmentProductType,
				service: serviceName,
				cost: serviceCost,
			},
		})
		setHasShippingMethod(true)

		if (serviceName === ShippingService.FedExGround) {
			trackEvent(TrackingEvent.GROUND)
		} else if (serviceName === ShippingService.PriorityOvernight) {
			trackEvent(TrackingEvent.OVERNIGHT)
		}
	}

	const onUpdateQuantity = (partId: string, newValue: number) => {
		shopDispatch({
			type: "update part cart quantity",
			payload: {
				partId,
				quantity: newValue,
			},
		})
	}

	const handleAccessorialChange = (isChecked: boolean, accessorial: ShippitAccessorial) => {
		shopDispatch({
			type: "toggle accessorial",
			payload: {
				accessorial,
				isChecked,
			},
		})
	}

	// Whenever either the shippitResponse or the customer accessorial defaults change,
	// set the accessorials in shop context to reflect the defaults.
	useEffect(() => {
		if (
			!checkout.shippitLoading &&
			shippitResponse?.accessorials &&
			Object.keys(shippitResponse.shipments).some((key) => key === "chemicals")
		) {
			shopDispatch({
				type: "set accessorials",
				payload: {
					accessorials: shippitResponse?.accessorials ?? [],
					defaults: accessorialDefaults ?? [],
				},
			})
		}
	}, [checkout.shippitLoading, shippitResponse, accessorialDefaults, shopDispatch])

	if (!checkout.shipToSiteId && !checkout.alternateAddress) {
		return <Paragraph color="secondary">First select your shipping address</Paragraph>
	}

	if (cart.length === 0) {
		return <Paragraph color="secondary">There are no products in your cart.</Paragraph>
	}

	const formatShippingDescription = (option: ShippingSelectorEcommType) => {
		return `${option.label}. ETD ${formatDate(option.estimatedDelivery)}  - `
	}

	const formatShippingCost = (option: ShippingSelectorEcommType) => {
		let cost = ""

		if (option.label !== ShippingService.TBD && !isDb) {
			cost = `${formatCurrency(option.cost ?? 0)}`
		}

		return (
			<Paragraph
				bold
				color="black"
				css={css`
					float: right;
				`}
			>
				{cost}
			</Paragraph>
		)
	}

	return (
		<>
			{shippitResponse === null && checkout.shippitLoading && (
				<LoadingSpinner
					justifyContent="flex-start"
					text="Calculating shipping options..."
				/>
			)}

			{/* Loop through the shipments we got back from Shippit. */}
			{!!shippitResponse &&
				Object.entries(shippitResponse.shipments).map(
					([shipmentType, shippitShipment], i) => {
						// The shippit response has a bunch of options for this shipment, and we'll
						// look at checkout state to see what's currently chosen.
						const cartShipment = checkout.shipments?.find(
							(s) => s.productType === shipmentType
						)

						const chosenOption = shippitShipment.service.find(
							(serviceOption) => serviceOption.name === cartShipment?.service
						)

						return (
							<React.Fragment key={shipmentType}>
								{!!chosenOption && (
									<Box display="flex" justifyContent="space-between">
										<Paragraph
											bold
											color="black"
											css={css`
												color: #111827;
												font-family: "Atlas Grotesk";
												font-size: 16px;
												font-style: normal;
												font-weight: 700;
												margin-bottom: 16px;
												line-height: 24px;
											`}
										>
											Estimated delivery:{" "}
										</Paragraph>
										<Paragraph bold color="success">
											{formatDate(chosenOption.estimatedDelivery)}
										</Paragraph>
									</Box>
								)}

								<Paragraph
									bold
									color="black"
									css={css`
										color: var(--Neutrals-900, #111827);
										font-family: "Atlas Grotesk";
										font-size: 14px;
										font-style: normal;
										font-weight: 500;
										margin-bottom: 12px;
										line-height: 24px;
									`}
								>
									Choose shipping option:
								</Paragraph>
								{checkout.shippitLoading ?
									<SkeletonRows />
								:	<>
										<RadioGroupEcomm
											htmlName={`shipment-${shipmentType}-options`}
											options={shippitShipment.service}
											valueAccessor="name"
											extraComponent={formatShippingCost}
											labelAccessor={formatShippingDescription}
											value={chosenOption?.name ?? null}
											onChange={(newValue, option) =>
												selectShippingMethod(
													shipmentType,
													option.name,
													option.cost
												)
											}
										/>
									</>
								}
								<GridContainer>
									<GridItem sm={6} xs={12} display="flex">
										{shipmentType === "chemicals" &&
											!checkout.shippitLoading &&
											!isDb && (
												<>
													<Paragraph bold color="black">
														Additional chemical shipping services:
													</Paragraph>

													<CheckboxGroupEcomm
														mt={0.75}
														groupName="categories"
														rows={shippitResponse.accessorials ?? []}
														onChange={(option, newState) =>
															handleAccessorialChange(
																newState,
																option
															)
														}
														valueAccessor={(option) =>
															option.lineItemTypeId.toString()
														}
														labelAccessor="lineItemDescription"
														checkedAccessor={(option) =>
															checkout.accessorials.some(
																(existingAccessorial) =>
																	existingAccessorial.lineItemTypeId ===
																	option.lineItemTypeId
															)
														}
													/>
												</>
											)}
									</GridItem>
									<GridItem sm={8} xs={12}>
										{shippitShipment.items.map((part) => {
											// First we'll check if it's in the actual cart or not. If it's not, don't show
											// anything, because it probably just got deleted and shippit hasn't updated yet.
											if (
												!cart.find(
													(cartPart) =>
														cartPart.part.onlinePartNumber ===
														String(part.partNumber)
												)
											) {
												return null
											}

											return (
												<Box key={part.id} display="flex" mb={2}>
													<PartImage
														src={part.imageUrl}
														maxWidth="8rem"
														mr={1}
													/>
													<div>
														<Paragraph mb={0.5}>
															{part.title}
														</Paragraph>
														<Paragraph small color="secondary">
															{part.partNumber}
														</Paragraph>
														<Paragraph small color="secondary">
															{formatCurrency(part.price)} each
														</Paragraph>
														<PriceEcomm
															price={part.price}
															quantity={part.quantity}
															isLoading={checkout.shippitLoading}
														/>
														<QuantityControl
															mt={0.5}
															value={part.quantity}
															onChange={(newValue) =>
																onUpdateQuantity(
																	String(part.id),
																	newValue
																)
															}
															onChooseZero={() =>
																setPartBeingRemoved(part)
															}
															useUpdateButton
														/>
													</div>
												</Box>
											)
										})}
									</GridItem>
								</GridContainer>

								{i !== (checkout.shipments?.length ?? 0) - 1 && <Divider mb={5} />}
							</React.Fragment>
						)
					}
				)}

			{shippitError && <ErrorText>{shippitError}</ErrorText>}

			<RemoveFromCartModal
				isOpen={!!partBeingRemoved}
				onClose={() => setPartBeingRemoved(null)}
				partId={partBeingRemoved ? String(partBeingRemoved.id) : null}
				partTitle={partBeingRemoved?.title ?? null}
			/>
		</>
	)
}
