import { useState, useEffect } from "react"
import { LineTaxEstimate, TaxPricingPost, useTaxEstimate } from "../portal-apps"

const useGetTaxes = (lines: LineTaxEstimate[], customerId: string, calculateTax: boolean) => {
	const [totalTax, setTotalTax] = useState<number>(0)
	const [error, setError] = useState<string | null>(null)
	const fetchTaxEstimate = useTaxEstimate()
	const storedValue = localStorage.getItem("ShopContext")

	useEffect(() => {
		const getTaxEstimate = async () => {
			if (lines.length === 0 || !customerId || !calculateTax) {
				setTotalTax(0)
				return
			}
			try {
				const response = await fetchTaxEstimate({
					customerId: customerId,
					lines: lines,
				} as TaxPricingPost & { lines: LineTaxEstimate[] })

				setTotalTax(response?.data?.total_tax)
			} catch (err) {
				console.error(err)
				setError("Error fetching tax estimate")
			}
		}

		getTaxEstimate()
	}, [fetchTaxEstimate, lines, customerId, calculateTax])

	return { totalTax, error, storedValue }
}

export default useGetTaxes
