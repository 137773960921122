import React, { useEffect, useMemo, useState } from "react"
import { css, SerializedStyles, Theme } from "@emotion/react"
import {
	AnimatedEntrance,
	Box,
	BoxProps,
	Button,
	IconButton,
	nextThread,
	NumericInput,
	Paragraph,
	SelectEcomm,
	usePrevious,
} from "@ncs/web-legos"

const options = [
	{
		value: "1",
		text: "1",
	},
	{
		value: "2",
		text: "2",
	},
	{
		value: "3",
		text: "3",
	},
	{
		value: "4",
		text: "4",
	},
	{
		value: "5",
		text: "5",
	},
	{
		value: "6",
		text: "6",
	},
	{
		value: "7",
		text: "7",
	},
	{
		value: "8",
		text: "8",
	},
	{
		value: "9",
		text: "9",
	},
	{
		value: "10+",
		text: "10+",
	},
]

interface QuantityControlProps extends Omit<BoxProps, "display" | "onChange"> {
	unitOfMeasure?: string | null | undefined
	value?: number
	onChange: (newValue: number) => void
	/** Fires when you select zero. */
	onChooseZero?: () => void
	/** What should the zero quantity be labeled as? */
	zeroQuantityText?: string
	/** Show an update button when user selects 10+ and edits the input field. User
	 * must click Update before the `onChange` prop is called. */
	useUpdateButton?: boolean
	iconCss?: SerializedStyles
	labelPosition?: "top" | "left"
}

export const QuantityControlEcomm: React.FC<QuantityControlProps> = ({
	value: propValue,
	onChange,
	onChooseZero,
	zeroQuantityText = "Remove",
	iconCss,
	useUpdateButton = false,
	labelPosition = "top",
	unitOfMeasure = "",
	...rest
}) => {
	const [mode, setMode] = useState<"select" | "input">(
		propValue && propValue > 9 ? "input" : "select"
	)
	const [localValue, setLocalValue] = useState(propValue)
	const [showUpdateButton, setShowUpdateButton] = useState(false)

	const prevPropValue = usePrevious(propValue)
	useEffect(() => {
		if (propValue !== prevPropValue && propValue !== localValue) {
			setLocalValue(propValue)
			if (propValue != null && propValue < 10) {
				setMode("select")
			}
		}
	}, [localValue, propValue, prevPropValue])

	const handleSelectChange = (newValue?: string | null) => {
		if (newValue === "0" && onChooseZero) {
			onChooseZero()
		} else if (newValue === "10+") {
			setMode("input")
			setShowUpdateButton(true)
			// Set us to 10.
			setLocalValue(10)
			nextThread(() => onChange(10))
		} else {
			setLocalValue(Number(newValue ?? 0))
			nextThread(() => onChange(Number(newValue ?? 0)))
		}
	}

	const handleInputChange = (newValue?: number) => {
		if (useUpdateButton) {
			setLocalValue(newValue)
		} else {
			// If you're not using the update button, then we will coerce your value to
			// zero if it's undefined, and we'll pass the changes right on up.
			setLocalValue(newValue ?? 0)
			nextThread(() => onChange(newValue ?? 0))
		}
	}

	const onSaveInput = () => {
		// If you update to 0, or empty, count that selecting 0.
		if (!localValue && onChooseZero) {
			onChooseZero()
			return
		}

		// Give the local value to the change handler prop.
		onChange(Number(localValue ?? 0))
		setShowUpdateButton(false)

		// Go back to dropdown mode if we can.
		if (localValue && localValue < 10) {
			setMode("select")
		}
	}

	const optionsWithZero = useMemo(() => {
		return [
			{
				value: "0",
				text: zeroQuantityText,
			},
			...options,
		]
	}, [zeroQuantityText])

	return (
		<Box display="inline-block" {...rest}>
			{labelPosition === "top" && (
				<Paragraph
					small
					color="secondary"
					customColor="#111827"
					mb={0.5}
					css={css`
						color: var(--Neutrals-900, #111827);
						font-family: "Atlas Grotesk";
						font-size: 16px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
					`}
				>
					Quantity
				</Paragraph>
			)}
			<Box display="flex" alignItems="center">
				{labelPosition === "left" && !showUpdateButton && (
					<Paragraph small color="secondary" mr={0.5}>
						Quantity
					</Paragraph>
				)}
				{mode === "select" && (
					<>
						<SelectEcomm
							options={onChooseZero ? optionsWithZero : options}
							value={String(localValue)}
							onChange={handleSelectChange}
							showNoSelectionOption={false}
							css={selectStyle}
							className="quantitySelect"
							iconCss={iconCss}
							mb={0}
						/>
						<span
							css={(theme: Theme) =>
								styleUnitOfMeasure(theme, useUpdateButton && showUpdateButton)
							}
						>
							{unitOfMeasure}
						</span>
					</>
				)}

				{mode === "input" && (
					<>
						<Box
							display="flex"
							justifyContent="flex-start"
							alignItems="center"
							width="100%"
						>
							<NumericInput
								css={inputStyle}
								value={localValue}
								onChange={handleInputChange}
								onFocus={() => setShowUpdateButton(true)}
								min={0}
								mb={0}
								decimalScale={0}
							/>
							<AnimatedEntrance show={useUpdateButton && showUpdateButton}>
								<Box pl={1}>
									<Button icon="check" onClick={onSaveInput} />
								</Box>
							</AnimatedEntrance>
							<span
								css={(theme: Theme) =>
									styleUnitOfMeasure(theme, useUpdateButton && showUpdateButton)
								}
							>
								{unitOfMeasure}
							</span>
						</Box>
					</>
				)}

				{!!onChooseZero && (
					<Box css={styleBoxTrashIconCss}>
						<IconButton icon="trash" onClick={onChooseZero} />
					</Box>
				)}
			</Box>
		</Box>
	)
}

const styleUnitOfMeasure = (theme: Theme, showUpdateButton: boolean) => css`
	margin-left: ${showUpdateButton ? "2.5em" : "4.5em"};
	color: var(--Neutrals-900, #111827);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

const selectStyle = css`
	width: 4rem;
	select {
		text-align: center;
	}
`
const inputStyle = (theme: Theme) => css`
	width: 5.85rem;
	${theme.breakpoints.down("md")} {
		width: 100%;
	}
	input {
		text-align: center;
	}
`

const styleBoxTrashIconCss = css`
	margin-top: -0.7em;
`
